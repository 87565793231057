import { lazy } from "react";
import Loadable from "../../components/Loadings/Loadable";

const MainLayout = Loadable(lazy(() => import('../../components/Layouts/MainLayout')));

// Auths
const SignInPage = Loadable(lazy(() => import('../../screens/auths/SignIn')));
const HomePage = Loadable(lazy(() => import('../../screens/mains/dashboard/Home')));

// Customer
const CustomerFormPage = Loadable(lazy(() => import('../../screens/mains/customer/form')));
const CustomerPage = Loadable(lazy(() => import('../../screens/mains/customer')));

// Pickup
const PickupMonitorPage = Loadable(lazy(() => import('../../screens/mains/pickup/index')));
const PickupInputFormPage = Loadable(lazy(() => import('../../screens/mains/pickup/input')));

// Finance
const TopUpFormPage = Loadable(lazy(() => import('../../screens/mains/finance/TopupForm')));
const TopUpListPage = Loadable(lazy(() => import('../../screens/mains/finance/TopUpList')));
const BusinessReportPage = Loadable(lazy(() => import('../../screens/mains/finance')));

// Status
const StatusDeliveryPage = Loadable(lazy(() => import('../../screens/mains/status')));


const appRoutes = [
    {
        path: '/',
        element: <MainLayout />,
        children: [
            { path: '', element: <HomePage /> },
        ],
    },
    {
        path: '/members',
        element: <MainLayout />,
        children: [
            { path: 'list', element: <CustomerPage /> },
            { path: 'form', element: <CustomerFormPage /> },
        ],
    },
    {
        path: '/finances',
        element: <MainLayout />,
        children: [
            { path: '', element: <BusinessReportPage /> },
            { path: 'topup-list', element: <TopUpListPage /> },
            { path: 'topup-form', element: <TopUpFormPage /> },
        ],
    },
    {
        path: '/status',
        element: <MainLayout />,
        children: [
            { path: '', element: <StatusDeliveryPage /> },
        ],
    },
    {
        path: '/pickups',
        element: <MainLayout />,
        children: [
            { path: 'monitor', element: <PickupMonitorPage /> },
            { path: 'input', element: <PickupInputFormPage /> },
        ],
    },
    {
        path: 'auths',
        children: [
            { path: 'sign-in', element: <SignInPage /> },
        ],
    }
];

export default appRoutes;